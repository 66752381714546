body {
    font-family: 'Montserrat', sans-serif;

    color: white;
    background-color: black;
}

.status {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 90vh;

    animation: fadein 2s;

    .title {
        position: relative;

        margin-right: 5em;

        #thonk {
            width: 11em;
        }

        #subtitle {
            font-style: italic;
            font-weight: 500;
            font-size: 14pt;
        }
    }

    .vl {
        border-left: 2px solid #474747;
        height: 30em;

        #puff {
            position: absolute;

            margin-top: 14em;
            margin-left: -1.4em;
        }
    }

    ul {
        list-style: none;

        li {
            margin: 1.25em;
            animation: fadein 2s;
        }

        .host {
            animation: fadein 1s;
        }
    }

    #puff-mobile {
        visibility: collapse;
        width:0;
        height:0;
    }
}

.skeleton {
    display: flex;

    width: 368px;

    #flag {
        margin-top: 0.7em;
        margin-right: 0.7em;
    }
}

.react-loading-skeleton {
    --base-color: #141414;
    --highlight-color: #3b3b3b;
}

::selection {
    color: black;
    background: white;
}

::-moz-selection {
    color: black;
    background: white;
}

@media screen and (max-width: 900px) {
    .status {
        display:inline-block;
        justify-content: center;
        height: 90vh;

        .title {
            padding: 1em;
        }

        .vl {
            visibility: collapse;
            height: 0;
        }

        ul {
            padding: 0;

            li {
                margin-top: 1.25em;
            }
        }

        #puff-mobile {
            position: absolute;
            visibility: visible;

            width: 3em;
            height: 3em;

            top: 0;
            right: 0;

            padding: 3em;
        }
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}