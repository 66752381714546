.host {
    display: flex;

    #flag {
        font-size: 32pt;
        margin-top: 0.1em;
        margin-right: 0.3em;
    }

    .details {
        #name {
            font-style: italic;
            font-size: 16pt;
            font-weight: 700;
            text-transform: uppercase;
        }

        #location {
            font-weight: 400;
        }

        #provider {
            font-size: 10pt;
            font-weight: 300;
            text-transform: uppercase;
        }

        #ping {
            color: #00FF00;

            font-size: 10pt;
            font-weight: 500;

            margin-right: 0.5em;
        }

        .error {
            color: red !important;
        }
    }
}